const isInIframe = () => {
  return window.self !== window.top;
};

type MessagesTypes =
  | 'currentAccountTransactionPrinterJob'
  | 'iframeIsLoaded'
  | 'contentHeight'
  | 'rejectOnlineMenuConfiguration'
  | 'closeOnlineMenuOnboarding'
  | 'changeRoute'
  | 'requestFullScreen'
  | 'exitFullScreen'
  | 'logout'
  | 'updateOnlineMenuSettings'
  | 'userPilotTrack'
  | 'completeOnlineMenuOnboarding'
  | 'openOnlineMenuActivate'
  | 'closeOnlineMenuActivate'
  | 'completeOnlineMenuActivation'
  | 'onlineMenuActivateIframeIsLoaded'
  | 'getExtensionDownloadLink'
  | 'getNativeAppDownloadLink'
  | 'checkExtensionIsEnabled'
  | 'checkNativeAppIsEnabled'
  | 'getWhatasppQrCode'
  | 'getChatbotSyncStatus'
  | 'detachDeviceFromChatbot'
  | 'enableChatbotNotifications';

const postMessage = (type: MessagesTypes, payload: string | object) => {
  if (!isInIframe()) return;

  window.parent.postMessage({ type, payload }, '*');
};

export { isInIframe, postMessage };
